<template>
    <div style="position: relative;">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button variant="primary" @click="redirectToEditProfile(undefined)" style="position: absolute; right: 40;">
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Novo perfil</span>
        </b-button>
      </app-breadcrumb>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Departamentos" label-for="searchDepartments">
              <v-select
                id="searchDepartments"
                v-model="departmentId"
                :reduce="(department) => department.id"
                :options="departments"
                :loading="loading.departaments"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Status" label-for="searchStatus">
              <v-select
                id="searchStatus"
                v-model="isActive"
                :reduce="(status_list) => status_list.value"
                :options="activeFilter"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              class="mr-1"
              :disabled="loading.table"
            >
              Limpar
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="search(1)"
              :disabled="loading.table"
            >
              <b-spinner v-if="loading.table" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.table ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="Não foram encontrados perfis para esta busca"
        :busy="loading.table"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Customer -->
        <template #cell(user_roles_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.user_roles_name }}</span>
          </div>
        </template>
        <!-- Column: departamento -->
        <template #cell(department_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.department_name ? data.item.department_name : "-"
            }}</span>
          </div>
        </template>
        <!-- Column: ativo -->
        <template #cell(is_active)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.is_active ? "Sim" : "Não"
            }}</span>
          </div>
        </template>
        <!-- Column: qtd -->
        <template #cell(qtd_users )="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.qtd_users }}</span>
          </div>
        </template>
        <!-- Column: ações -->
        <template #cell(actions)="data">
          <div class="text-wrap text-right">
            <b-button-group>
              <b-dropdown variant="link" no-caret right toggle-class="pl-0">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="redirectToEditProfile(data.item.id)">
                  <feather-icon class="text-body" icon="EditIcon" />
                  <span class="align-middle ml-50">Editar perfil</span>
                </b-dropdown-item>
                <b-dropdown-item @click="redirectToBindProfile(data.item.id)">
                  <feather-icon class="text-body" icon="LinkIcon" />
                  <span class="align-middle ml-50">Vincular perfis</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.qtd_users <= 0 && data.item.is_active === 1"
                  @click="updateProfile(data.item)"
                >
                  <feather-icon class="text-body" icon="LockIcon" />
                  <span class="align-middle ml-50">Inativar perfil</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import * as sharedTypes from "@/modules/shared/store/types";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import * as types from "../store/types";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { getRangeDates } from "@/helpers/date_picker";
import { createWppNpsMessage } from "@/helpers/whatsapp_messages";
import useAppConfig from "@core/app-config/useAppConfig";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    DynamicSelect,
    BPopover,
    vSelect,
    flatPickr,
    AppBreadcrumb
  },

  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        table: false,
        departaments: false,
      },
      tableColumns: [
        { key: "user_roles_name", label: "Nome", sortable: true },
        { key: "department_name", label: "Departamento", sortable: true },
        { key: "is_active", label: "Ativo", sortable: true },
        { key: "qtd_users", label: "Qtd. de usuários", sortable: true },
        { key: "actions", label: "Ações", sortable: false },
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      userRolesName: undefined,
      departmentId: undefined,
      qtdUsers: undefined,
      isActive: undefined,
      activeFilter: [
        { id: 1, name: "Ativo", value: true },
        { id: 2, name: "Inativo", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profilesSearchResult: types.PROFILES_RESULT,
      departments: sharedTypes.DEPARTMENTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.profilesSearchResult.data || [];
    },
    totalItems: function () {
      return this.profilesSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.profilesSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.profilesSearchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.getDepartaments()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os departamentos. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.departaments = false;
      });
  },
  methods: {
    ...mapActions({
      searchProfiles: types.SEARCH_PROFILES,
      getDepartaments: sharedTypes.GET_DEPARTMENTS,
      inactivateProfile: types.INACTIVATE_PROFILE,
    }),
    search(currentPage = undefined) {
      this.loading.table = true;
      const {
        userRolesName,
        departmentId,
        qtdUsers,
        isActive,
        itemsPerPage,
        order,
      } = this;
      this.searchProfiles({
        user_roles_name: userRolesName,
        department_id: departmentId,
        qtd_users: qtdUsers,
        is_active: isActive,
        order,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
        limit: itemsPerPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar a lista de perfis. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    redirectToBindProfile(id) {
      this.$router.push({
        name: "access-profile-users",
        params: {
          id: id,
        },
      });
    },
    redirectToEditProfile(id) {
      if(!id){
        this.$router.push({
          name: "access-profile",
        });
      }
      this.$router.push({
        name: "access-profile",
        params: {
          id: id,
        },
      });
    },
    updateProfile(profile) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja inativar o perfil ${profile?.user_roles_name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Inativar",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.inactivateProfile(profile)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `O perfil ${profile?.user_roles_name} foi inativado`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao inativar o perfil. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    clearSearch() {
      this.sortField = null;
      this.userRolesName = undefined;
      this.departmentId = undefined;
      this.qtdUsers = undefined;
      this.isActive = undefined;
      this.search();
    },
    sort(event) {
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? "desc" : "asc";
        this.order = [event.sortBy, direction];
        this.sortField = event.sortBy;
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
